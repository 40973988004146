$white: #f8f8ff;
$grey: #bbb;
$dark: #212121;
$greyer: #454545;
$med-grey: #999;
$teal: #047f57;
$gold: #ffb647;
$rust: #a53d1e;
$navy: #001524;
$almond: #ffecd1;
$text-field-grey: #f1f1f1;
$text-field-grey: #f1f1f1;

.text-dark {
  color: $dark;
}

.text-white {
  color: $white;
}

.text-grey {
  color: $med-grey;
}

.text-greyer {
  color: $greyer;
}

.text-rust {
  color: $rust;
}

.text-navy {
  color: $navy;
}

.text-gold {
  color: $gold;
}

.text-primary {
  color: $teal;
}

.bg-almond {
  background-color: $almond;
}

.bg-light-green {
  background-color: #e0efea;
}

.bg-light-grey {
  background-color: #f5f5f5;
}

.bg-text-field-grey {
  background-color: $text-field-grey;
}

.bg-white {
  background-color: $white;
}

.border-primary {
  border-color: #c1dfd5;
}

.bg-gold {
  background-color: $gold;
}
