@import "sizes";
@import "spacing";
@import "borders";
@import "flex";
@import "typography";
@import "colors";
@import "css/bootstrap.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button {
  font: unset;
  text-align: unset;
  background-color: unset;
  border: unset;
  cursor: pointer;
}

body {
  line-height: 1.5rem;
  margin: 0;
  font-size: 100%;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  color: $dark;
  overflow-wrap: break-word;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-1 {
  height: 1px;
}

.height-full {
  min-height: 100vh;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

a.no-link-format {
  color: inherit;
  text-decoration: inherit;
}

a {
  color: #008eda;
}

.link {
  color: #008eda;
  text-decoration: underline;
}

.button-styled-href {
  color: $dark;
  text-decoration: unset;
}

.footer {
  color: $med-grey;
  padding: 20px;

  a {
    color: $med-grey;
    text-decoration: underline;
  }
}

mark {
  background-color: $gold;
}

.splash-image {
  width: 95vw;
  margin-top: -30px;
  margin-left: -10px;
}

.landing-container {
  margin-top: 2rem;
}

.landing-title {
  padding-right: 1rem;
}

.container-when-large {
  margin: 0;
}

.height-85 {
  min-height: 85vh;
}

.height-15 {
  min-height: 15vh;
}

.onhover-parent {
  .onhover-child {
    display: none;
  }
}

.about-img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 1.2px 2.7px -4px rgba(0, 0, 0, 0.022), 0 3px 6.9px -4px rgba(0, 0, 0, 0.031),
    0 6.2px 14.2px -4px rgba(0, 0, 0, 0.039), 0 12.8px 29.2px -4px rgba(0, 0, 0, 0.048),
    0 35px 80px -4px rgba(0, 0, 0, 0.07);
}

@media (min-width: $xxs) {
  .landing-title {
    padding-right: 4rem;
  }
}

@media (min-width: $xs) {
  .splash-image {
    margin-top: -240px;
    margin-left: -100px;
    width: 50rem;
    z-index: -5;
  }

  .landing-container {
    margin-top: 6rem;
  }

  .landing-title {
    padding-right: 4rem;
  }

  .container-when-large {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $sm) {
  .count {
    width: 2.5rem;
    height: 2.5rem;
  }

  .splash-image {
    margin-top: -400px;
    margin-left: 100px;
    width: 50rem;
    z-index: -5;
  }

  .onhover-parent:hover {
    .onhover-child {
      display: block;
    }
  }
}

.display-count::after {
  color: white;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: bold;
  bottom: 33px;
  left: 10px;
  z-index: 10;
  position: relative;
  content: attr(data-count);
  display: block;
}

.display-count-longer::after {
  color: white;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: bold;
  bottom: 33px;
  left: 6px;
  z-index: 10;
  position: relative;
  content: attr(data-count);
  display: block;
}

.hover-edit {
  color: $dark;

  p {
    margin-bottom: 6px;
    margin-top: 12px;
  }

  &:hover {
    background-color: $text-field-grey;
  }
}

td,
th {
  width: 4rem;
  height: 1rem;
}

td.label,
th.label {
  width: 24rem;
  padding-right: 2rem;
}

.form-max-width {
  form {
    max-width: 30rem;
  }
}
