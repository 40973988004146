.mla {
  margin-left: auto;
}

.ml-15 {
  margin-left: -15px;
}

// padding and margin
$space_sizes: (xxl, 6rem), (xl, 4rem), (l, 2rem), (m, 1.5rem), (d, 1rem), (s, 0.5rem), (xs, 0.25rem),
  (xxs, 0.125rem), (z, 0rem);

$orientation_list: (v, h);
$direction_list: ((t, top), (r, right), (b, bottom), (l, left));

@each $space_sizes, $size in $space_sizes {
  .pa#{$space_sizes} {
    padding: $size;
  }
  .ma#{$space_sizes} {
    margin: $size;
  }
}
@each $direction_list, $direction in $direction_list {
  @each $space_sizes, $size in $space_sizes {
    .p#{$direction_list}#{$space_sizes} {
      padding-#{$direction}: $size;
    }
    .m#{$direction_list}#{$space_sizes} {
      margin-#{$direction}: $size;
    }
  }
}
@each $orientation_list, $orientation in $orientation_list {
  @each $space_sizes, $size in $space_sizes {
    @if $orientation_list == "v" {
      .p#{$orientation_list}#{$space_sizes} {
        padding-bottom: $size;
        padding-top: $size;
      }
      .m#{$orientation_list}#{$space_sizes} {
        margin-bottom: $size;
        margin-top: $size;
      }
    } @else if $orientation_list == "h" {
      .p#{$orientation_list}#{$space_sizes} {
        padding-left: $size;
        padding-right: $size;
      }
      .m#{$orientation_list}#{$space_sizes} {
        margin-left: $size;
        margin-right: $size;
      }
    }
  }
}
