h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1;
}

.text-xxl {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.1;
}

.text-xl {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.1;
}

.text-l {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
}

.text-m {
  font-style: normal;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.2;
}

.text-d {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2;
}

.text-s {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2;
}

.text-xs {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.2;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.bold {
  font-weight: 700 !important;
}

.underline {
  text-decoration: underline;
}

.all-caps {
  text-transform: uppercase;
}

.extra-bold-caps {
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 700;
}

.bold-caps {
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 500;
}

.non-caps {
  text-transform: none;
}

@media (min-width: $sm) {
  .text-xxl {
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    line-height: 1.1;
  }

  .text-xl {
    font-weight: 500;
    font-size: 2.5rem;
  }

  .text-l {
    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
  }
}

.font-logo {
  font-family: "Staatliches", "Open Sans", sans-serif;
}
